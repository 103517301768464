import React from "react";

function Services() {
  return (
    <div>
      Services
    </div>
  );
}

export default Services;