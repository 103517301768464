import React from "react";

function Pricing() {
  return (
    <div>
        Pricing
    </div>
  );
}

export default Pricing;